import * as React from "react";
import { PropsWithChildren } from "react";
import Helmet from "react-helmet";

import { Post } from "../../../contexts/blog/domain/post";

interface ProstPost extends PropsWithChildren<any> {
  post: Post;
}

const PostMarkupSchema: React.FC<ProstPost> = (props: ProstPost) => {
  const { post } = props;
  const markup = `
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://almayser.es/${post.slug}/"
  },
  "headline": "${post.title}",
  "description": "${post.seo.description}",
  "image": "${post.imagen.asset.gatsbyImageData.images.fallback.src}",
  "author": {
    "@type": "Person",
    "name": "${post.authors[0].name}",
    "image": "${post.authors[0].photo.asset.gatsbyImageData.images.fallback.src}",
    "sameAs": [
      "${post.authors[0].socials.instagram}",
      "${post.authors[0].socials.facebook}",
      "${post.authors[0].socials.tiktok}"
    ],
    "jobTitle": "BFRP Practitioner",
    "worksFor": {
      "@type": "Organization",
      "name": "AlmaySer"
    }
  },
    "publisher": {
    "@type": "Organization",
    "name": "AlmaySer",
    "logo": {
      "@type": "ImageObject",
      "url": "https://almayser.es/static/fbc351473d46da07f9e15c63043a2860/4b620/logo.webp"
    }
  },
  "datePublished": "${post.publishedAt}"
}
`;
  return (
    <Helmet>
      <script type="application/ld+json">{markup}</script>
    </Helmet>
  );
};

export default PostMarkupSchema;
