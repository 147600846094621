import "./post.scss";

import BlockContent from "@sanity/block-content-to-react";
import { graphql, useStaticQuery } from "gatsby";
import { PageContext } from "gatsby/internal";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";

import Layout from "../../components/common/layout/layout";
import PostMarkupSchema from "../../components/common/SEO/PostMarkupSchema";
import SEO from "../../components/common/SEO/SEO";
import { Post } from "../../contexts/blog/domain/post";
import Sidebar from "../../views/blog/Sidebar/Sidebar";
import Hero from "../../views/shared/Hero/Hero";

interface PostPageContext extends PageContext {
  post: Post;
}

function getUrlVideo(ref: string): string {
  // the ref is:  file-hasshsssssssss-mp4
  const refSplit = ref.split("-");
  const video = refSplit[1];
  const extension = getExtension(ref);
  return `https://cdn.sanity.io/files/uqw9j09h/production/${video}.${extension}`;
}

function getExtension(ref: string): string {
  const refSplit = ref.split("-");
  return refSplit[2];
}

const PagePost: React.FC<PostPageContext> = (props: PostPageContext) => {
  const data = useStaticQuery(graphql`
    query sanitySettingQuery {
      site {
        siteMetadata {
          projectId
          dataset
        }
      }
    }
  `);
  const { projectId, dataset } = data.site.siteMetadata;

  const { post } = props.pageContext;
  const image: string = post.imagen.asset.gatsbyImageData.images.fallback.src;

  const serializers = {
    types: {
      file: ({ node }) => {
        const { asset } = node;
        const url = getUrlVideo(asset._ref);
        return (
          <video width="100%" controls>
            <source src={url} type={`video/${getExtension(asset._ref)}`} />
          </video>
        );
      },
    },
  };

  return (
    <Layout>
      <PostMarkupSchema post={post} />
      <SEO
        title={`${post.seo.title} | AlmaySer`}
        description={post.seo.description}
        canonical={post.slug}
        image={image}
        robots={"index, follow"}
      />
      <div className={"o-post"}>
        <Hero
          title={post.title}
          description={[post.seo.description]}
          is_home={false}
        >
          <SanityImage
            {...post.imagen}
            width={900}
            height={500}
            alt={post.title}
            className={"is-sepia"}
          />
        </Hero>
        <div className={"container grid-70-30"}>
          <main>
            <section className={"m-post-content"}>
              <div className={"a-line-center"} />
              <BlockContent
                blocks={post.content}
                projectId={projectId}
                dataset={dataset}
                serializers={serializers}
              />
            </section>
          </main>
          <Sidebar />
        </div>
      </div>
    </Layout>
  );
};
export default PagePost;
